import axios from 'axios'
import {
	START,
	LOGON,
	GET_FILMDATA,
	PEOPLE,
	FILMS,
	PROP,
	CHAT,
	MENU,
	LIST,
	GET_TAPE,
	LOVE,
	UPD_PLAC,
	YEAR0,
	FILEDATA,
	PIC,
	GET_PICTS,
	FRIEND,
	TEXT,
	GET_USER,
	GET_PERSON,
	GET_FIND,
	MODE,
	LANG,
	LOGIN,
	UPD_COUNT,
	UPD_COUNTR,
	GET_NEXT,
	GET_NEXTR,
	COLL,
	HALL,
	NEWU,
	NUMB,
	ADVT,
    SAMP
  } from '../constants'

export const start = (login, text) => dispatch => {
	dispatch({
	    type:  START,
		login: login,
		text:  text
	})
}

export const getfilmdata = (n, code, side, tape) => dispatch => {
	axios.get('/index/info', { params: { code: code,
		                                 side: side,
										 tape: tape 
									   }})
		.then(res => dispatch({
			type: GET_FILMDATA,
			side: side,
			card: res.data,
			chek: n
		}))
		.catch(err => console.log(err))
}

export const entr = (pass, stam) => dispatch => {
	axios.get('/index/entr', { params: { pass: pass,
		                                 stam: stam }})
		.then(res => dispatch({
			type: res.data[0]?GET_USER:ADVT,
			user: res.data[0],
			bill: res.data[1],
			tape: res.data[2],
			frnd: res.data[3],
			pict: res.data[4],
			film: res.data[5],
			text: res.data[6]
		}))
		.catch(err => console.log(err))
}

export const tapes = (flag, code, n, stam) => dispatch => {
    axios.get('/index/tape', { params: { tape: n,
		                                 flag: flag,
										 stam: stam }})
       .then(res => dispatch({
		    type: GET_TAPE,
			rank: code,
			post: res.data[0],
			tape: res.data[1]
        }))
        .catch(err => console.log(err))
}

export const people = (n, code, side) => dispatch => {
	axios.get('/index/pepl', { params: { code: code,
		                                 side: side           
                             }})
	.then(res => dispatch({
		   type: PEOPLE,
		   payload: n,
		   side:   side,
		   person: res.data[0],
		   bill:   res.data[1],
		   frnd:   res.data[2],
		   film:   res.data[3],
		   pict:   res.data[4],
		   pers:   code
		}))
		.catch(err => console.log(err))
}

export const films = (stam) => dispatch => {
	axios.get('/index/film', { params: { stam: stam }})
	.then(res => dispatch({
		  type: FILMS,
		  post: res.data[0],
		  film: res.data[1]
		}))
		.catch(err => console.log(err))
}

export const prop = (user) => dispatch => {
	axios.get('/index/prop', { params: { user: user} })
		.then(res => dispatch({
	        type: PROP,
		    users:  res.data[0],
			picts:  res.data[1],
			bill:   res.data[2],
			person: res.data[3],
			frnd:   res.data[4]
		}))
		.catch(err => console.log(err))
}

export const chat = (user, users) => dispatch => {
	axios.get('/index/chat', { params: { user: user } })
		.then(res => dispatch({
	        type: CHAT,
		    film: res.data,
			users: users
	 }))
	 .catch(err => console.log(err))
}

export const list = (chat) => dispatch => {
	dispatch({
	        type: LIST,
		    chat: chat
	})
}

export const swit = (menu) => dispatch => {
	dispatch({
	        type: MENU,
		    menu: menu
	})
}

export const Love = (love, user, side, coll) => dispatch => {
	axios.get('/index/love', { params: { love: love,
	                                     user: user,
										 side: side,
										 coll: coll
									   }})
		.then(res => dispatch({
			type:  LOVE,
			side:  res.data[0],
			usern: res.data[1],
			prop:  res.data[2],
			swch:  '0f220123150000p1.jpg',
			numb:  res.data[4],
			love:  res.data[3],
			blue:  side === 1?'/Users/0/h.png':'/Users/0/del.png'
		}))
		.catch(err => console.log(err))
}

export const samp = (side, swch, numb, film) => dispatch => {
	dispatch({
	    type: SAMP,
	    side: side,
	    swch: swch,
		numb: numb,
		blue: side === 1?'/Users/0/h.png':'/Users/0/del.png',
		film: film
	})
}
export const updateplacard = (count, coun) => dispatch => {
	dispatch({
		type: UPD_PLAC,
		placard: count,
		coun: coun
	})
}

export const setyear0 = (year0) => dispatch => {
	dispatch({
	    type: YEAR0,
		year0: year0
	})
}

export const coll = (code, stam, numb) => dispatch => {
    axios.get('/index/coll', { params: { code: code,
		                                 stam: stam }})
       .then(res => dispatch({
		    type: COLL,
			film: res.data[0],
			fold: res.data[1],
			numb: numb
        }))
        .catch(err => console.log(err))
}

export const filedata = (fileData, name) => dispatch => {
	dispatch({
	    type: FILEDATA,
		fileData: fileData,
		name: name
	})
}

export const pic = (showPik) => dispatch => {
	dispatch({
	    type: PIC,
		pic:  showPik
	})
}

export const del = (showPik, usernum) => dispatch => {
    axios.get('/delete', { params: { showPik: showPik,
		                             usernum: usernum
	                               }})
       .then(res => dispatch({
		    type: GET_PICTS,
		    picts: res.data
        }))
        .catch(err => console.log(err))
}

export const file = (formData, usernum, showPik, title) => dispatch => {
    axios.post('/upload', formData, { 
		       headers: {
			       "Content-type": "multipart/form-data",
		       },
			   params: { usernum: usernum,
				         showPik: showPik,
						 title:   title
			           }
			   })
			.then(res => dispatch({
				type: GET_PICTS,
				picts: res.data[0],
				user: res.data[1]
			}))
        .catch(err => console.log(err))
}

export const frnd = (foto, stam, dnside, name, year, user) => dispatch => {
	axios.get('/index/frnd', { params: { foto: foto,
		                                 stam: stam,
										 dnside: dnside,
										 name: name,
										 year: year,
										 user: user
							  }})
	.then(res => dispatch({
		   type: FRIEND,
		   friends: res.data
		}))
		.catch(err => console.log(err))
}

export const text = (user, code, Text, nextValue, numb) => dispatch => {
	axios.get('/index/text', { params: { user: user,
		                                 code: code,
		                                 text: Text,
										 star: nextValue,
										 numb: numb
										 } })
	.then(res => dispatch({
		  type: TEXT,
		  film: res.data[0],
		  name: res.data[1]
		}))
		.catch(err => console.log(err))
}

export const newUser = (name, mail, birth, avat) => dispatch => {
	axios.get('/index/join', {params: { name:  name,  
		                                mail:  mail,
										birth: birth,
										avat:  avat }})
		.then(res => dispatch({
			type:  LOGON,
			text: res.data
		}))
		.catch(err => console.log(err))
}

export const getyear = year => dispatch => {
	axios.get('/index/year', { params: { year: year } })
		.then(res => dispatch({
			type: GET_FIND,
			find: res.data[0],
			card: res.data[1],
		}))
		.catch(err => console.log(err))
}

export const getperson = (code) => dispatch => {
	axios.get('/index/pers', { params: { code: code } })
	.then(res => dispatch({
		type: GET_PERSON,
		year1: res.data[0][0],
		year2: res.data[0][1],
		name1: res.data[1],
		name2: res.data[2]
	}))
	.catch(err => console.log(err))
}

export const setmode = (mode) => dispatch => {
	dispatch({
	    type: MODE,
	    mode: mode
	})
}

export const setnumb = (numb) => dispatch => {
	dispatch({
	    type: NUMB,
	    numb: numb
	})
}

export const hall = (stam, login) => dispatch => {
	dispatch({
	    type: HALL,
	    stam: stam,
		login: login
	})
}

export const finded = (name) => dispatch => {
	axios.get('/index/find', { params: { find: name }})
		.then(res => dispatch({
            type: GET_FIND,
			find: res.data[0]
    	}))
		.catch(err => console.log(err))
}

export const setlang = (lang) => dispatch => {
	dispatch({
	    type: LANG,
	    lang: lang
	})
}

export const ava = () => dispatch => {
    axios.get('/index/avat')
       .then(res => dispatch({
		    type: LOGIN,
			payload: res.data
        }))
        .catch(err => console.log(err))
}

export const updatecount = (count) => dispatch => {
	 dispatch({
            type: UPD_COUNT,
			payload: count
		})
}

export const updatecountr = (countr) => dispatch => {
	dispatch({
		   type: UPD_COUNTR,
		   payload: countr
	   })
}

export const newu = () => dispatch => {
	dispatch({
		   type: NEWU
	   })
}

export const getnext = (trigger, posters, n, file) => dispatch => {
  axios.get('/index/scroll', { params: { numb: n, file: file } })
	   .then (res => dispatch(insert('up', trigger, posters, res.data, GET_NEXT)))
}

export const getnextr = (triggerr, animate, n, file) => dispatch => {
    axios.get('/index/scroll', { params: { numb: n, file: file } })
	   .then (res => dispatch(insert('up', triggerr, animate, res.data, GET_NEXTR)))
}

export const getlast = (trigger, posters, n, file) => dispatch => {
	let pg = 'dn';
	axios.get('/index/pgdn', { params: { hide: n, file: file} })
		 .then (res => dispatch(insert(pg, trigger, posters, res.data, GET_NEXT)))
}
export const getlastr = (triggerr, animate, n, file) => dispatch => {
	let pg = 'dn';
	axios.get('/index/pgdn', { params: { hide: n, file: file } })
		 .then (res => dispatch(insert(pg, triggerr, animate, res.data, GET_NEXTR)))
}

const insert = (pg, trigger, posters, data, type)  => {
	let cut
	if (pg === 'up'){
	  if (trigger === 0) {
		  cut = 10
		  trigger = 1
	  }
	  else if (trigger === 1) {
		  cut = 0
		  trigger = 2
	  }
	  else if (trigger === 2) {
		  cut = 5
	 	  trigger = 0
	  }
    }
	else if (pg === 'dn'){
		if (trigger === 0) {
			cut = 5
			trigger = 2
		}
		else if (trigger === 2) {
			cut = 0
			trigger = 1
		}
		else if (trigger === 1) {
			cut = 10
			trigger = 0
		}
	}
	posters.splice(cut, 5, [data[1][0], data[1][1]],
		                   [data[2][0], data[2][1]],
						   [data[3][0], data[3][1]],
						   [data[4][0], data[4][1]],
						   [data[5][0], data[5][1]]
					)
	return {
		type: type,
		payload: posters,
		numbs: data[0][0],
		trigger: trigger
	}

}
