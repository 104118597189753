import React from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {getyear} from '../actions/card'

export const Year = () => {
  const dispatch = useDispatch()
  let timer = []

  const buttClick = (year) => {
    dispatch(getyear(year))
  }
  for (let x = 1895; x <= 2024; x++) {
    timer.push(x)
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", right: "-44px", top: "55px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/rt.png"}
        alt="up"
        />
      );
    }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", left: "-42px", top: "55px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/lt.png"}
        alt="dn"
      />
    );
  }
  var settings = {
    infinite: true,
    slidesToShow: 7,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true
  }
  return (
    <div>
      <Slider {...settings}>
        {timer.map(year => (
          <label key = {year}>
          <div id ="year">
            <button onClick={() => buttClick(year)}>{ year }</button>
          </div>
          </label>
        ))}
      </Slider>
    </div>
  )
}
export default (Year)