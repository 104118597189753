import React, {useState} from "react";
import styled from 'styled-components'
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {people, films, prop, getfilmdata, tapes, coll, swit, chat, entr} from '../actions/card'

const POSTERS = styled.div`
  height: 150px;
  background: url(/Users/0/1kadr.png) no-repeat;
  background-size: 100%;
`;

export const Side = ({  side, socket }) => {
  const dispatch = useDispatch()
  const code = useSelector(state => state.post.user)
  const stam  = useSelector(state => state.post.stam) 
  const users = useSelector(state => state.post.chat)
  const swch  = useSelector(state => state.post.swch)
  const film  = useSelector(state => state.post.film)
  const tape  = useSelector(state => state.post.tape)
  const menu  = useSelector(state => state.post.menu)
  const temp  = useSelector(state => state.post.temp)
  const dnside = useSelector(state => state.post.dnside)

  const head = {
    height: "150px",
    backgroundSize: "100%",
    border: "groove white"
  }
  const left = {
    width: "18%",
    height: "746px",
    float: "left",
    borderRight: "2px",
    marginBottom: "-15px",
    textAlign: "center"
  }
  const chek0 = {
    left: "103px",
    position: "absolute",
    width: "50px"
  }
  const numb = {
    background: "url(/Users/0/numb.png) no-repeat",
    position: "fixed",
    left: "69px",
    color: "azure",
    width: "34px",
    height: "20px",
    backgroundSize: "100% 100%",
  }

  const check0 = <img style = {chek0} src={"/Users/0/check.png"} alt = 'check'/>
  const [Checkit, setCheckit] = useState(-1)
  let folk, post

  const mode = (n, code, flag, timestam) => {
    if (flag === 0) {
      dispatch(swit(menu))
      setCheckit(() => -1)
    }
    if (flag === 1) {
      dispatch(films(stam))
      setCheckit(() => -1)
    }
    if (flag === 2) {
      dispatch(coll(timestam, stam, 0))
      setCheckit(() => film.length-1)
    }
    if (flag === 3) {
      dispatch(prop(stam))
      setCheckit(() => -1)
    }
    if (flag === 4) {
      dispatch(chat(stam, users))
    }
    if (flag === 5) {
      dispatch(getfilmdata(n, code, side, tape))
      setCheckit(() => n)
    }
    if (flag === 6) {
      dispatch(tapes(flag, code + 'p1.jpg', code, stam))
    }
    if (flag === 7) {
      dispatch(coll(timestam, stam, temp.length - n - 1))
      setCheckit(() => n)
    }
    if (flag === 8) {
      socket.emit('film', [timestam, 3])
    }
    if (flag === 9) {
      dispatch(people(n, code, side))
      setCheckit(() => n)
    }
    if (flag === 10) {
      dispatch(entr('', stam))
      setCheckit(() => -1)
    }
  }
  if (!code) {
    if (side === 1) {
      post = <div style = {head}>
      <img className = "image"
                 src = {'/Users/0/film.jpg'}
                 alt = 'film' />
      </div>
      folk = film
    }
    if (side === 2) {
      post = <div style = {head}>
      <img className = "image"
                 src = {'/Users/0/' + swch}
                 alt = 'poster'
      />
      </div>
      folk = temp
    }
  }
  if (code) {
    if (side === 1) {
      const srce = '/Users/' + code + 'p1.jpg'
      post = <div style = {head}>
              <img className = "image"
                         src = {srce}
                         alt = 'film'
                     onClick = {() => mode(0, '', 10, '')} /></div>
      folk = film
    }
    if (side === 2) {
      post = <div style = {head}>
              <img className = "image"
                         src = {'/Users/0/' + swch}
                         alt = ''
                     onClick = {() => mode(0, '', 0, '')} /></div>
      folk = temp
    }
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", right: "-8px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/up.png"}
        alt="up"
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", width: "36px", height: "36px", left: "-9px", zIndex:"1", border: "1px solid white", background: "white"}}
        onClick={onClick}
        src={"/Users/0/dn.png"}
        alt="dn"
      />
    );
  }
  const settings = {
    infinite: true,
    slidesToShow: 4,
    vertical: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  
  const slide = <div>
                <Slider {...settings}>
                  {folk.map(post => (
                  //  <label key = {post[0]}>
                    <POSTERS>
                      {Checkit === post[0]-1 && Checkit >= 0 && side === dnside ? check0 : ""}
                      {post[0] ? <div style = {numb}>{post[0]}</div> : ""}
                      <img className = "image"
                                 src = {post[2] + post[1] +  post[3]}
                                 alt = ''
                             onClick = {() => mode(post[0] - 1, post[4], post[7], post[1])}
                      />
                    </POSTERS>
                  //  </label>
                  ))}
                </Slider>
               </div>
  return (
    <div style = {left}>
      {post}
      {slide}
    </div>
  )
}
export default (Side)